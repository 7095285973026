import React from 'react'
import Helmet from 'react-helmet'
import './fonts.css'
import '../style/style.scss'
import Header from './header'
import Footer from './Footer'

const Layout = ({ children }) => (
  <div>
    <Helmet>
      <title>Proteo</title>
      <meta name="description" content="Proteo" />
      <meta
        name="google-site-verification"
        content="zXWRhNXIDZWtBxDKVq_rZjKhv8FkIIFqvVqYmZKjpL8"
      />

      <script src="https://www.googletagmanager.com/gtag/js?id=UA-119674773-2" />
    </Helmet>
    <Header />
    {children}
    <Footer />
  </div>
)

export default Layout
