import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import styled from 'styled-components'

const NavbarContainer = styled(Container)`
  @media screen and (min-width: 1200px) {
    max-width: 1300px;
  }
`
const Logo = styled.img`
  max-width: 100%;

  @media screen and (max-width: 575px) {
    max-width: 200px;
  }
`

const NavLink = props => {
  return (
    <Link
      className={`nav-link  `}
      activeStyle={{
        color: 'var(--primary)',
      }}
      to={props.href}
    >
      {props.children}
    </Link>
  )
}

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      location: '',
    }
  }
  location = ''
  componentDidMount() {
    this.setState({
      location: window.location.pathname,
    })
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <div id={'top'}>
        <Navbar
          light
          expand="xl"
          style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
        >
          <StaticQuery
            query={graphql`
              {
                data: contentfulGlobal {
                  id
                  logo {
                    title
                    description
                    file {
                      url
                    }
                  }
                  menu {
                    links: menu {
                      link
                      label
                      children {
                        link
                        label
                      }
                    }
                  }
                }
              }
            `}
            render={({ data }) => {
              return (
                <NavbarContainer>
                  <NavbarBrand tag={Link} to="/">
                    <Logo src={data.logo.file.url} />
                  </NavbarBrand>
                  <NavbarToggler onClick={this.toggle} className="border-0" />
                  <Collapse
                    isOpen={this.state.isOpen}
                    style={{ minHeight: '120px' }}
                    navbar
                  >
                    <Nav className="ml-auto " navbar>
                      {' '}
                      {data.menu.links.map((item, i) => {
                        if (
                          item.children !== undefined &&
                          item.children !== null
                        ) {
                          return (
                            <UncontrolledDropdown
                              key={item.link}
                              nav
                              inNavbar
                              setActiveFromChild
                            >
                              <DropdownToggle caret nav>
                                {' '}
                                {item.label}
                              </DropdownToggle>
                              <DropdownMenu>
                                {item.children.map(link => (
                                  <div key={link.link}>
                                    <DropdownItem
                                      className="text-uppercase"
                                      active={this.state.location === link.link}
                                      tag={Link}
                                      to={link.link}
                                    >
                                      {link.label}{' '}
                                      {link.children && 'Has child'}
                                    </DropdownItem>
                                  </div>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )
                        } else {
                          return (
                            <NavItem
                              className="text-uppercase"
                              key={i}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              <NavLink href={item.link}>{item.label}</NavLink>
                            </NavItem>
                          )
                        }
                      })}{' '}
                    </Nav>
                  </Collapse>
                </NavbarContainer>
              )
            }}
          />
        </Navbar>
      </div>
    )
  }
}
