import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import styled from 'styled-components'
import classnames from 'classnames'

const WideContainer = styled(Container)`
  @media screen and (min-width: 1200px) {
    max-width: 1300px;
  }
`
const HeaderLink = styled(Link)`
  color: var(--dark);
  display: block;
  font-size: 1.2em;
  margin-bottom: 1.5em;
  font-weight: 700;
`
const HeaderSpan = styled.span`
  color: var(--dark);
  display: block;
  font-size: 1.2em;
  margin-bottom: 1.5em;
  font-weight: 700;
`

const BodyLink = styled(Link)`
  color: var(--dark);
`
const BodySpan = styled.span`
  color: var(--dark);
`
const ContactInfo = styled.div`
  br {
    display: none;
  }
  @media screen and (min-width: 992px) {
    white-space: nowrap;
    br {
      display: block;
    }
  }
`
const Icon = styled.a`
  display: block;
  width: ${props => props.width};
  height: ${props => props.height};
  background: url(${props => props.bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 2rem;
`
const GoToTop = styled.div`
  width: 50px;
  height: 50px;
  margin-left: auto;
  background: url('/images/top.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export default class Footer extends Component {
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            contact: contentfulContactPage {
              contanctInfo {
                g: childMarkdownRemark {
                  html
                }
              }
            }
            globals: contentfulGlobal {
              footerLogo {
                title
                description
                file {
                  url
                }
              }
              footerMenu {
                menu {
                  link
                  label
                  children {
                    link
                    label
                  }
                }
              }
            }
          }
        `}
        render={({ contact, globals }) => {
          return (
            <div>
              <div style={{ borderTop: '1px solid rgba(0,0,0,0.2)' }}>
                <WideContainer>
                  <Row className="justify-content-between">
                    <Col
                      lg="2"
                      sm="12"
                      className="py-4 d-lg-block d-flex flex-column "
                    >
                      <div>
                        {' '}
                        <img
                          alt={globals.footerLogo.description}
                          src={globals.footerLogo.file.url}
                          className="w-100 d-block mb-lg-4 mb-2"
                          style={{ maxWidth: '250px' }}
                        />
                      </div>
                      <ContactInfo
                        className=" d-flex flex-column"
                        dangerouslySetInnerHTML={{
                          __html: contact.contanctInfo.g.html,
                        }}
                      />
                    </Col>
                    <Col
                      lg={{ size: 9 }}
                      className="py-4 d-flex flex-wrap ml-lg-1 justify-content-between"
                    >
                      {globals.footerMenu.menu.map((item, i) => (
                        <div key={i} className="pb-5">
                          {item.link !== null && (
                            <HeaderLink to={item.link}>{item.label}</HeaderLink>
                          )}
                          {item.link == null && (
                            <HeaderSpan to={item.link}>{item.label}</HeaderSpan>
                          )}
                          {item.children &&
                            item.children.map((child, idx) => (
                              <p
                                key={idx}
                                className={classnames(
                                  {
                                    'py-1': child.label === null,
                                  },
                                  {
                                    'py-0 mb-2': child.label !== null,
                                  }
                                )}
                              >
                                {child.label !== null &&
                                  child.link !== null && (
                                    <BodyLink to={child.link}>
                                      {child.label}
                                    </BodyLink>
                                  )}{' '}
                                {child.label !== null &&
                                  child.link == null && (
                                    <BodySpan to={child.link}>
                                      {child.label}
                                    </BodySpan>
                                  )}{' '}
                              </p>
                            ))}
                        </div>
                      ))}
                    </Col>
                  </Row>
                </WideContainer>
              </div>
              <div
                style={{ borderTop: '1px solid rgba(0,0,0,0.1)' }}
                className="py-3"
              >
                {' '}
                <WideContainer className="d-flex align-items-center">
                  <Icon
                    bg="/images/linkedin.png"
                    width="32px"
                    height="32px"
                    href="https://www.linkedin.com/company/proteo-uk/"
                  />
                  <Icon
                    bg="/images/youtube.png"
                    width="32px"
                    href="https://www.youtube.com/channel/UCbCy-72i1EfHFQjxH1fQxdA"
                    height="24px"
                  />
                  <Icon
                    bg="/images/facebook.png"
                    href="https://www.facebook.com/ProteoUK"
                    width="32px"
                    height="32px"
                  />
                  <GoToTop onClick={this.goToTop} />
                </WideContainer>
                <WideContainer className="d-flex align-items-center py-1">
                  <Link className="text-dark" to="/privacy">
                    {' '}
                    Copyright @ 2018 Proteo Limited
                  </Link>
                </WideContainer>
              </div>
            </div>
          )
        }}
      />
    )
  }
}
